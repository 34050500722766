<template lang="pug">
.habit-confirmation-wrapper
  b-row(v-if="isLoading")
    b-col(cols="12")
      b-card.py-5
        b-card-body.text-center
          loading(
            :active="true" 
            :is-full-page="false"
            :color="colors.primary"
          )

  div(v-else)
    b-row(v-if="!processData")
      h1 {{ $t('message.process_not_found') }}

    div(v-if="processData && !confirmationPage.route")
      validation-observer(ref="eventInfo", tag="form")
        b-row
          b-col(cols="12")
            b-card(:title="processName", :sub-title="processData.goal")
              b-card-text
                | {{ processData.description }}
          b-col(cols="12")
            b-card(:title="$t('message.information_event_confimation')")
              b-form-group
                label {{ $t('label.trabajador') }}
                validation-provider(:name="$t('label.trabajador')", rules="required", v-slot="{ errors }")
                  v-select#v-select-worker(
                    v-model="selectedWorker",
                    :reduce="(workersSupervised) => workersSupervised.value",
                    :disabled="selectWorkerDisabled",
                    :options="workersSupervised",
                    :placeholder="$t('placeholder.select_evaluado')",
                    label="title"
                  )
                  small(class="text-danger") {{ errors[0] }}
              b-form-group
                label {{ $t('label.evaluador') }}
                validation-provider(:name="$t('label.evaluador')", rules="required", v-slot="{ errors }")
                  v-select#v-select-worker(
                    v-model="selectedSupervisor",
                    :reduce="(supervisors) => supervisors.value",
                    :disabled="selectSupervisorDisabled",
                    :options="supervisors",
                    :placeholder="$t('placeholder.select_evaluador')",
                    label="title"
                  )
                  small(class="text-danger") {{ errors[0] }}
              b-form-group(:label="$t('label.fetch_confirmation')", label-for="v-date")
                b-form-datepicker#v-date(v-model="event.date", :disabled="selectDateDisabled")
              //b-form-group(label="Hora de inicio", label-for="v-time")
                b-form-timepicker#v-code(v-model="event.start")
              //b-form-group(label="Hora de término", label-for="v-time")
                b-form-timepicker#v-code(v-model="event.end")
              b-button(
                class="mt-1 mr-1"
                :disabled="isSubmitting"
                v-show="!continueConfirmation",
                v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                variant="warning",
                @click="schedulleEvent()"
              )
                | {{ $t('message.program_confirmation_fetch') }}
              b-button(
                class="mt-1 mr-1"
                :disabled="isSubmitting"
                v-show="!continueConfirmation",
                v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                variant="primary",
                @click="enableConfirmation"
              )
                | {{ $t('message.continuar_confirmation') }}

        // Metadata 
        b-row(v-show="processData.metadata && processData.metadata.length", :style="{ visibility: metadataVisible ? 'visible' : 'hidden' }")
          b-col(cols="12")
            b-card(:title="$t('aditional_information')")
              b-card-text
                b-form-group(
                  v-for="(metadata, indexDos) in processData.metadata", :key="indexDos"
                  :label="$t(`metadata.${metadata.name === 'peak' ? 'peak_long' : metadata.name}`)",
                  description=""   
                )
                  validation-provider(
                    :name="$t(`metadata.${metadata.name}`)"
                    rules="required"
                    v-slot="{ errors }"
                  )
                    v-select(
                      v-if="metadata.type === 'select'"
                      v-model="metadata.answer",
                      :multiple="false",
                      :options="metadataOptionsFiltered[metadata.name]",
                      :placeholder="$t(`placeholder.${metadata.name}`)",
                      @search="(search, loading) => onSearch(search, loading, metadata.name)",
                      v-b-tooltip.focus.v-info.top="$t('search_criteria')"
                      :key="'select'+indexDos"
                    )
                    b-form-input(
                      v-else-if="metadata.type === 'input'"
                      v-model="metadata.answer"
                      :placeholder="$t(`placeholder.${metadata.name}`)"
                    )
                    small(class="text-danger") {{ errors[0] }}

      div(v-show="continueConfirmation")


        // Activities
        b-row(v-for="activity in processData.activities", :key="activity.id")
          b-col(cols="12")
            b-card(:title="activity.name", :sub-title="activity.goal")
              b-card-text
                | {{ activity.description }}

              b-row(
                v-for="behaviour in activity.behaviours",
                :key="behaviour.id"
              )
                b-col(cols="12")
                  behaviour-question(
                    :behaviour="behaviour",
                    :allow-add-commitment="(commitment_functionality || inDemo || celulosa_arauco_view) && isOnline",
                    :clientId="clientId",
                    :workerId="selectedWorker || ''",
                    :activityName="activity.name",
                    @answered="(answer) => (behaviour.answer = answer)"
                    @commitmentModified="(commitment) => (behaviour.commitment = commitment)"
                    @commitmentRemoved="() => (delete behaviour.commitment)"
                    )

        // Upload image
        b-row(v-if="processData.requireImage")
          b-col(cols="12")
            b-card(
              :title="$t('message.upload_image')",
              :sub-title="$t('message.upload_image_description')"
            )
              b-card-text
                b-button.mr-1(
                  :disabled="isSubmitting"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                  type="button",
                  variant="info",
                  @click="uploadImage()"
                )
                  | {{ $t('message.upload') }}
                image-compressor(
                  style="display: none",
                  :done="getImgCompressed",
                  ref="compressor"
                )
                small(v-if="imgData.fileInfo")
                  | {{ imgData.fileInfo.name }}
                small(v-else, class="text-danger")
                  | {{ $t('message.upload_image_required') }}

        // Add improvement
        b-row(v-if="add_improvement_in_confirmation")
          b-col(cols="12")
            b-card(
              :title="commitment_functionality ? $t('add_commitment') : $t('add_improvement')",
            )
              b-form-group
                b-col(cols="12")
                  ul.list-group.mb-1
                    li.list-group-item.d-flex.justify-content-between.align-items-center.pt-0.pb-0(v-for="(improvement, index) in improvements" :key="index")
                      span
                        | {{ improvement.note }}
                      div
                        edit-button(@clicked="handleImprovementEdit(improvement, index)")
                        delete-button(v-b-modal="'improvement list' + index")
                        b-modal(
                          :id="'improvement list' + index" 
                          centered='' 
                          no-close-on-backdrop='' 
                          modal-class='modal-danger' 
                          ok-variant='danger' 
                          cancel-variant='outline-secondary' 
                          :title="$t('message.confirm_action')" 
                          :ok-title="$t('message.delete')" 
                          :cancel-title="$t('message.cancel')" 
                          @ok="removeImprovement(index)"
                        )
                          | {{ commitment_functionality ? $t('message.confirm_delete_commitment') : $t('message.confirm_delete_improvement') }}
                  b-button(
                    :disabled="isSubmitting",
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'",
                    type="button",
                    variant="primary"
                    @click="isImprovementHandlerSidebarActive = true"
                  )
                    | {{ $t('message.add') }}

        // End confirmation
        b-row
          b-col(cols="12")
            b-card(
              :title="$t('message.End_Confirmation')",
              :sub-title="$t('message.please_check_all_answers_correct')"
            )
              b-card-text
                b-button.mr-2(
                  v-if="isOnline",
                  :disabled="isSubmitting",
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'",
                  type="button",
                  variant="outline-primary"
                  @click="validateAndSubmit(processData, true)"
                )
                  | {{ $t('message.save_draft') }}
                b-button(
                  :disabled="isSubmitting || (processData.requireImage && !imgData.fileInfo)",
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                  type="submit",
                  variant="primary",
                  @click="validateAndSubmit(processData, false)"
                )
                  | {{ $t('message.submit') }}

      todo-task-handler-sidebar(
        v-model="isImprovementHandlerSidebarActive",
        :task="improvement",
        :taskIndex="improvementIndex",
        :clear-task-data="clearImprovementData",
        :domains="domains",
        :optionsMetadata="optionsMetadata",
        handlerId="improvement",
        @add-task="addImprovement",
        @update-task="updateImprovement"
      )
    b-col(v-if="confirmationPage.route" cols="12")
      b-card(
          class="text-primary d-flex flex-column text-center mb-0"
          style="height: 70vh !important; padding-top: 10rem"
        )
          feather-icon(
            icon="CheckCircleIcon"
            size="30"
            class="text-success"
          )
          h4(class="text-primary mt-2") {{ $t('action_completed') }}
          h5(class="text-secondary mt-2") {{ addMsgOnConfirmationPage && $t(`${addMsgOnConfirmationPage}`) }} {{ confirmationPage.msg && $t(`${confirmationPage.msg}`) }} 
          b-button(
            @click="$router.push(confirmationPage.route)"
            variant="primary"
            class="mt-2"
          ) Ok
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch, set } from "@vue/composition-api";
import { BRow, BCol, BCard, BCardText, BButton, BFormGroup, BFormSelect, BFormDatepicker, BFormTimepicker, BCardBody } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useCommon from "@/views/organization/useCommon";
import useConfirmationForm from './useConfirmationForm'
import BehaviourQuestion from './BehaviourQuestion.vue'
import i18n from '@/libs/i18n'
import useNotifications from '@/composables/useNotifications'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from "@validations";
import ImageCompressor from '@/views/habit/ImageCompressor.vue';
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import { VBTooltip } from 'bootstrap-vue'
import TodoTaskHandlerSidebar from '@/views/apps/todo/TodoTaskHandlerSidebar.vue'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import endpoints from '@/libs/endpoints'
import Loading from 'vue-loading-overlay'
import { colors } from '@/constants'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormSelect,
    BehaviourQuestion,
    vSelect,
    BFormDatepicker,
    BFormTimepicker,
    BCardBody,
    ValidationObserver,
    ValidationProvider,
    ImageCompressor,
    TodoTaskHandlerSidebar,
    EditButton,
    DeleteButton,
    Loading
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isNewConfirmation: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    processName() {
      return i18n.t('message.process') + `: ${this.processData.name}`
    },
  },
  setup(props) {
    const {
      improvements, userData, show, submit, event, process: processData, selectedWorker, selectWorkerDisabled, selectedSupervisor, selectSupervisorDisabled, schedulleEvent, continueConfirmation, isSubmitting, selectDateDisabled, enableConfirmation, interval, startTimer, stopTimer, metadataVisible, confirmationPage, addMsgOnConfirmationPage, isLoading
    } = useConfirmationForm(props.isNewConfirmation)
    const { getWorkersSupervisedForDropDown, workersSupervised, getSupervisorsForDropDown, supervisors, getMetadataForDropDown, metadataTypeSelectNotMapped, metadataNotMapped } = useCommon()
    const isOnline = computed(() => store.state.app.isOnline)
    const { showErrorMessage } = useNotifications()
    const clientId = userData.role !== "admin" ? userData.client.$oid : null;
    const eventInfo = ref(null)
    const compressor = ref(null)
    const imgData = ref({})
    const metadataOptionsFiltered = ref({})
    const improvementIndex = ref(-1)
    const isImprovementHandlerSidebarActive = ref(false)
    const TODO_APP_STORE_MODULE_NAME = 'app-todo'
    const now = new Date()
    const inDemo = endpoints.app_id === process.env.VUE_APP_APP_ID_DEMO

    const { getDomains, getDatesFromRange } = useCommonDashboards()
    const domains = computed(() => getDomains(clientId))

    const blankImprovement = {
      _id: null,
      client_id: { link: clientId },
      note: '',
      dueDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      description: '',
      assignee: null,
      tags: [],
      completed: false,
      deleted: false,
      important: false,
      imgData: {}
    }

    const improvement = ref(JSON.parse(JSON.stringify(blankImprovement)))

    const clearImprovementData = () => {
      improvement.value = JSON.parse(JSON.stringify(blankImprovement))
      improvementIndex.value = -1
    }

    const optionsMetadata = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].optionsMetadata)

    watch(metadataNotMapped, val => {
      if (val && val.length) {
        val.forEach(e => {
          // Set default creation date
          if (e.name === "creation_date") {
            e.answer = `${now.getFullYear()}-${now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1}-${now.getDate() < 10 ? "0" + now.getDate() : now.getDate()}`
          }
          // Set default instance leader
          if (e.name === "instance_leader") e.answer = userData.username
        })
        blankImprovement.metadata = val
        if (!improvement.value._id) improvement.value = { ...improvement.value, metadata: val }
      }
    })

    const handleImprovementEdit = (improvementData, index) => {
      improvementIndex.value = index
      improvement.value = improvementData
      isImprovementHandlerSidebarActive.value = true
    }

    const addImprovement = (improvementData) => {
      improvements.value.push(improvementData)
    }

    const updateImprovement = (improvementData, index) => {
      improvements.value[index] = improvementData
    }

    const removeImprovement = (index) => {
      improvements.value.splice(index, 1)
    }

    const { router, route } = useRouter()

    const { commitment_functionality, add_improvement_in_confirmation, celulosa_arauco_view } = JSON.parse(localStorage.getItem('clientData') || '{}')

    const uploadImage = () => {
      compressor.value.$el.click()
    }

    const getImgCompressed = (obj) => {
      imgData.value = {
        fileInfo: obj.compressed,
        destinationFolder: `${userData.client.$oid}/confirmations`
      }
    }

    const openTaskHandlerSidebar = () => {
      store.commit('app-todo/SET_TOGGLE_TASK_HANDLER_SIDEBAR', true)
    }

    const validateEventInfo = () => new Promise((resolve, reject) => {
      eventInfo.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validateAndSubmit = (processData, isDraft) => {
      if (isDraft) submit(processData, imgData.value, isDraft)
      else {
        validateEventInfo()
          .then(() => submit(processData, imgData.value, isDraft))
          .catch((error) => {
            console.log(error)
            showErrorMessage(i18n.t('message.requiredFieldsIncomplete'))
          })
      }
    };

    const onSearch = (search, loading, property) => {
      // Only enable search when text input is at least 3 characters long
      if (search.length < 3) {
        metadataOptionsFiltered.value[property] = metadataTypeSelectNotMapped.value[property]?.slice(0, 100)
      } else {
        loading(true)
        // Simulated ajax query
        setTimeout(() => {
          metadataOptionsFiltered.value[property] = metadataTypeSelectNotMapped.value[property]?.filter(e => e.toLowerCase().includes(search.toLowerCase()))
          loading(false)
        }, 100)
      }
    }

    onMounted(async () => {
      getWorkersSupervisedForDropDown()
      getSupervisorsForDropDown()
      getMetadataForDropDown({ type: "select", option: "notMapped" })
      getMetadataForDropDown({ category: "improvement", option: "notMapped" })
      show()
      if (route.value.params.user || props.isNewConfirmation) startTimer()
    })

    onUnmounted(() => {
      if (interval.value) stopTimer()
    })

    watch(metadataTypeSelectNotMapped, () => {
      for (const metadata in metadataTypeSelectNotMapped.value) {
        set(metadataOptionsFiltered.value, metadata, metadataTypeSelectNotMapped.value[metadata]?.slice(0, 100))
      }
    })

    return {
      show,
      submit,
      event,
      processData,
      workersSupervised,
      supervisors,
      selectedWorker,
      selectWorkerDisabled,
      selectedSupervisor,
      selectSupervisorDisabled,
      selectDateDisabled,
      schedulleEvent,
      enableConfirmation,
      continueConfirmation,
      isSubmitting,
      required,
      eventInfo,
      validateAndSubmit,
      compressor,
      uploadImage,
      getImgCompressed,
      imgData,
      isOnline,
      commitment_functionality,
      add_improvement_in_confirmation,
      openTaskHandlerSidebar,
      onSearch,
      metadataOptionsFiltered,
      metadataVisible,
      isImprovementHandlerSidebarActive,
      improvement,
      improvements,
      domains,
      clearImprovementData,
      improvementIndex,
      handleImprovementEdit,
      addImprovement,
      removeImprovement,
      updateImprovement,
      optionsMetadata,
      clientId,
      inDemo,
      confirmationPage,
      addMsgOnConfirmationPage,
      celulosa_arauco_view,
      isLoading,
      colors,
    }
  },
}
</script>

<style lang="scss">
.form-group {
  label {
    font-size: 1.2rem;
    font-weight: 400;
  }
}
</style>