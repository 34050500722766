<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm(); resetVariables()"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <div>
            <b-button
              v-if="taskLocal._id && !readOnly"
              size="sm"
              :variant="taskLocal.completed ? 'outline-success' : 'outline-secondary'"
              @click="changeTaskStatus"
              class="mr-1"
            >
              {{ taskLocal.completed ? $t('completed') : $t('mark_complete') }}
            </b-button>
            <h5 v-else class="mb-0">
              {{ taskIndex !== -1
                ? commitmentFunctionality
                  ? $t('commitment')
                  : $t('message.improvement')
                : commitmentFunctionality
                  ? $t('add_commitment')
                  : $t('add_improvement')
              }}
            </h5>
            <feather-icon
              v-if="docEvidence || imgEvidence"
              icon="PaperclipIcon"
              size="15"
              style="opacity: 0.5;"
            />
          </div>

          <!-- Modal to upload evidence of completed commitments -->
          <b-modal
            v-model="showModal"
            centered
            no-close-on-backdrop
            :modal-class="taskLocal.completed ? 'modal-danger' : 'modal-success'"
            :ok-variant="taskLocal.completed ? 'danger' : 'success'"
            cancel-variant="outline-secondary"
            :title="$t('message.confirm_action')"
            ok-title="OK"
            :cancel-title="$t('message.cancel')"
            :ok-disabled="!docEvidence && !imgEvidence && !taskLocal.completed"
            @ok="handleModalOk"
            @hide="handleModalHide"
          > {{ !taskLocal.completed ? $t('label.fileRequiredMessage') : $t('label.evidenceDeletedMessage') }}

            <!-- Buttons to upload evidence -->
            <div v-if="!taskLocal.completed" class="my-1">
              <b-button   
                @click="uploadImageOrFile('img')"
                variant="outline-secondary"
                class="mt-1 mr-1"
                :disabled="disabledImg"
                > {{ $t('label.img') }}
              </b-button>
              <image-compressor
                ref="compressor"
                style="display: none"
                :done="getImgEvidence"
              />
              <b-button
                @click="uploadImageOrFile('doc')"
                variant="outline-secondary"
                class="mt-1"
                :disabled="disabledDoc"
                > {{ $t('label.document') }}
              <input
                type="file"
                ref="docSelector"
                style="display: none"
                accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                @change="getDocEvidence"
              />
              </b-button>
            </div>
            <small v-if="!docEvidence && !imgEvidence && !taskLocal.completed" class="text-danger">
              {{ $t('label.fileRequired') }}
            </small>
            <div v-if="docEvidence || imgEvidence">
              <feather-icon
                  icon="PaperclipIcon"
                  size="15"
                  style="opacity: 0.5;"
              />
              <small class="text-secondary">
                {{ docEvidence ? docEvidence.name : imgEvidence.name  }} 
              </small>
            </div>
          </b-modal>
          
          <!-- Button to view evidence -->
          <b-button
            v-if="taskLocal.evidenceKey && taskLocal.evidenceKey.key && !deleteEvidence"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-primary"
            class="button-outline"
            style="opacity: 0.6;"
            @click="taskLocal.evidenceKey.type === 'img' ? openImage(taskLocal.evidenceKey.key) : openDocument(taskLocal.evidenceKey.key)"
          >
            {{ $t('label.viewFile') }}
          </b-button>

          <div>
            <feather-icon
              v-show="taskLocal._id && !readOnly"
              icon="TrashIcon"
              class="cursor-pointer"
              v-b-modal="handlerId"
            />
            <b-modal
              :id="handlerId"
              centered
              no-close-on-backdrop
              modal-class="modal-danger"
              ok-variant="danger"
              cancel-variant="outline-secondary"
              :title="$t('message.confirm_action')"
              :ok-title="$t('message.delete')"
              :cancel-title="$t('message.cancel')"
              @ok="$emit('remove-task', taskLocal, taskIndex); hide();"
            >
              {{ commitmentFunctionality ? $t('message.confirm_delete_commitment') : $t('message.confirm_delete_improvement') }}
            </b-modal>
            <feather-icon
              v-if="!readOnly"
              class="ml-1 cursor-pointer"
              icon="StarIcon"
              size="16"
              :class="{ 'text-warning': taskLocal.important }"
              @click="taskLocal.important = !taskLocal.important"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Title -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.Title')"
              rules="required"
            >
              <b-form-group
                :label="$t('label.Title')"
                label-for="task-title"
              >
                <b-form-input
                  id="task-title"
                  v-model="taskLocal.note"
                  autofocus
                  trim
                  :disabled="readOnly"
                  :state="getValidationState(validationContext)"
                  :placeholder="commitmentFunctionality ? $t('placeholder.commitment_title') : $t('placeholder.improvement_title')"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Assignee -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.assignee')"
              rules="required"
            >
              <b-form-group
                :label="$t('label.assignee')"
                label-for="assignee"
              >
                <v-select
                  v-model="taskLocal.assignee"
                  label="name"
                  class="assignee-selector"
                  input-id="assignee"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="commitmentFunctionality ? workersTotalNotMapped : workersSupervisedNotMapped"
                  :disabled="readOnly"
                  :placeholder="$t('placeholder.responsible')"
                >

                  <template #option="{ name }">
                    <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                  </template>

                  <template #selected-option="{ name }">
                    <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Subscribers -->
            <b-form-group
              :label="$t('label.subscribers')"
              label-for="subscribers"
            >
              <v-select
                v-model="taskLocal.subscribers"
                label="title"
                input-id="subscribers"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="commitmentFunctionality ? workersTotal : workersSupervised"
                :multiple="!readOnly"
                :disabled="readOnly"
                :reduce="(subscriber) => subscriber.value"
                :placeholder="$t('placeholder.subscribers')"
              >
              </v-select>
            </b-form-group>

            <!-- due Date -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.due_date')"
              rules="required"
            >

              <b-form-group
                :label="$t('label.due_date')"
                label-for="due-date"
              >
                <flat-pickr
                  v-model="taskLocal.dueDate"
                  class="form-control"
                  :disabled="readOnly"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!--Tag -->
            <validation-provider
              #default="validationContext"
              name="Tag"
              rules="required"
            >

              <b-form-group
                label="Tag"
                label-for="tag"
              >
                <v-select
                  v-model="taskLocal.tags"
                  id="selectTags"
                  input-id="tags"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="domains"
                  :reduce="option => option.value"
                  :multiple="!readOnly"
                  :disabled="readOnly"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Metadata -->
            <div v-if="taskLocal.metadata && taskLocal.metadata.length">
              <b-form-group
                v-for="metadata in taskLocal.metadata" :key="metadata._id"
                :label="$t(`metadata.${metadata.name}`)"
                :label-for="$t(`metadata.${metadata.name}`)"
                description=""
              >
                <v-select
                  v-if="metadata.type === 'select'"
                  v-model="metadata.answer"
                  :multiple="false"
                  :options="optionsMetadata[metadata.name]"
                  :disabled="readOnly"
                  :placeholder="$t(`placeholder.${metadata.name}`)"
                />
                <b-form-input
                  v-if="metadata.type === 'input'"
                  v-model="metadata.answer"
                  :disabled="readOnly"
                  :placeholder="$t(`placeholder.${metadata.name}`)"
                />
                <flat-pickr
                  v-if="metadata.type === 'datepicker'"
                  v-model="metadata.answer"
                  class="form-control"
                  :disabled="readOnly"
                />
              </b-form-group>
            </div>

            <!-- Image -->
            <!-- Open Image -->
            <b-form-group
              v-if="taskLocal._id && taskLocal.imageKey"
              :label="$t('Image')"
              label-for="image"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="info"
                @click="openImage(taskLocal.imageKey)"
              >
                {{ $t('message.open') }}
              </b-button>
            </b-form-group>

            <!-- Upload Image -->
            <b-form-group
              v-if="!taskLocal._id"
              :label="$t('Image')"
              label-for="image"
            >
              <b-button
                class="mr-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="info"
                @click="uploadImage()"
              >
                {{ $t('message.upload') }}
              </b-button>
              <image-compressor
                style="display: none"
                :done="getImgCompressed"
                ref="compressor"
              />
              <small v-if="imgData.fileInfo">
                {{imgData.fileInfo.name}}
              </small>
            </b-form-group>

            <!-- Description -->
            <b-form-group
              :label="$t('label.description')"
              label-for="task-description"
            >
              <!-- Quill editor -->
              <quill-editor
                :id="`quil-content-${handlerId}`"
                v-model="taskLocal.description"
                class="quil-content border-bottom-0"
                :options="editorOption"
                :disabled="readOnly"
              />
              <!-- Toolbar -->
              <div
                :id="`quill-toolbar-${handlerId}`"
                class="quill-toolbar d-flex justify-content-end border-top-0"
              >
                <!-- Add a bold button -->
                <button class="ql-bold" style="opacity: 0.5"/>
                <button class="ql-italic" style="opacity: 0.5"/>
                <button class="ql-underline" style="opacity: 0.5"/>
                <button class="ql-align" style="opacity: 0.5"/>
                <button class="ql-link" style="opacity: 0.5"/>
              </div>
            </b-form-group>

            <!-- Assistance -->
            <b-form-group
              v-if="taskLocal._id && assistanceIAFunctionality"
              label-for="assistance"
              class="mt-3 mb-2"
            >
              <b-button
                type="button"
                variant="blackbtn btn-dark"
                @click="handleAssistance(taskLocal.assistance, taskLocal._id)"
              >
              {{ taskLocal.assistance ? $t('View') : $t('New') }}  {{ $t('Assistance') }} IA
            </b-button>
            <img src="@/assets/images/avatars/iconoChatbot.png" alt="chatbot" class="chatbot">
            </b-form-group>

            <!-- Form Actions -->
            <div v-if="!readOnly" class="d-flex mt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ taskLocal._id || taskIndex !== -1 ? $t('message.update') : $t('message.add') }}
              </b-button>
              <div v-if="commitmentFunctionality && taskLocal._id">
                <b-button
                  v-if="taskLocal.origin"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="warning"
                  class="mr-2"
                  type="button"
                  @click="goToOrigin(taskLocal.origin, taskLocal.origin_id)"                
                >
                  {{ $t('message.origin') }}
                </b-button>
                <b-button
                  v-else
                  disabled
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-primary"
                  class="button-outline"
                >
                  {{ $t('message.direct_commitment') }}
                </b-button>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BModal, VBModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs, onMounted, watch, ref } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import useTaskHandler from './useTaskHandler'
import useCommon from '@/views/organization/useCommon'
import ImageCompressor from '@/views/habit/ImageCompressor.vue';
import i18n from '@/libs/i18n'
import store from '@/store'
import endpoints from '@/libs/endpoints'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BModal,

    // 3rd party packages
    vSelect,
    flatPickr,
    quillEditor,
    ImageCompressor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    taskIndex: {
      type: Number,
      default: -1,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
    domains: {
      type: Array,
      required: true,
    },
    optionsMetadata: {
      type: Object,
      required: true,
    },
    handlerId: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const {
      taskLocal,
      resetTaskLocal,
      onSubmit,
      uploadImage,
      getImgCompressed,
      compressor,
      imgData,
      resetVariables,
      goToOrigin,
      showModal,
      changeTaskStatus,
      handleModalOk,
      uploadImageOrFile,
      getDocEvidence,
      docSelector,
      docEvidence,
      handleModalHide,
      getImgEvidence,
      imgEvidence,
      disabledImg,
      disabledDoc,
      deleteEvidence
    } = useTaskHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetTaskLocal, props.clearTaskData)

    const {
      getWorkersSupervisedForDropDown,
      workersSupervisedNotMapped,
      workersSupervised,
      getWorkersTotalForDropDown,
      workersTotalNotMapped,
      workersTotal,
      handleAssistance,
      openImage,
      openDocument
    } = useCommon()

    const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality
    const assistanceIAFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').assistanceIA_functionality

    const editorOption = {
      modules: {
        toolbar: `#quill-toolbar-${props.handlerId}`,
      },
      placeholder: i18n.t('message.write_a_description'),
    }

    onMounted(() => {
      if (commitmentFunctionality) {
        getWorkersTotalForDropDown()
        getWorkersTotalForDropDown("notMapped")
      } else {
        getWorkersSupervisedForDropDown(null, "addId")
        getWorkersSupervisedForDropDown("notMapped", "addId")
      }
    })

    return {
      // Add New
      taskLocal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // UI
      editorOption,

      // Workers dropdown
      workersSupervised,
      workersTotal,
      workersSupervisedNotMapped,
      workersTotalNotMapped,

      // Image upload
      uploadImage,
      getImgCompressed,
      compressor,
      imgData,
      openImage,

      // Evidence of task Local completed
      showModal,
      changeTaskStatus,
      handleModalOk,
      uploadImageOrFile,
      getDocEvidence,
      docSelector,
      docEvidence,
      handleModalHide,
      getImgEvidence,
      imgEvidence,
      disabledImg,
      disabledDoc,
      deleteEvidence,
      openDocument,

      // Others
      resetVariables,
      commitmentFunctionality,
      goToOrigin,
      handleAssistance,
      assistanceIAFunctionality
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

.quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + .quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.button-outline {
  border: none !important;
}

#selectTags {
   color: black;
}

.chatbot{
  width: 3rem; 
  height: auto;
  padding-left: 0.5rem;
}
</style>
